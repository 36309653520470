// @import "bootstrap/dist/css/bootstrap.min.css";

$primary: #98694c;
// $offCanvasBg-shade: $primary;
$offCanvasBg-shade: #98694c;
// Customize styles
// $primary: #ff0000;
// $btn-primary-bg: $primary;
// $btn-primary-border: $primary;

// Import custom styles
// @import "custom-styles";

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: scroll;
    font-family: "Bai Jamjuree", sans-serif !important;
    // background-color: #fafafa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root{
    background-color: #fafafa;
}

.offcanvas-backdrop {
    background-color: $offCanvasBg-shade !important;
}


// ::-webkit-scrollbar {
//     background-color: #f5f5f5;
//     /* change the background color */
// }

// ::-webkit-scrollbar-thumb {
//     background-color: #ccc;
//     /* change the thumb color */
// }

// ::-webkit-scrollbar {
//     width: 12px;
//     /* change the width */
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 6px;
//     /* make the thumb rounded */
// }
// ::-webkit-scrollbar {
//     width: 10px;
//     /* change the width */
// }

// ::-webkit-scrollbar-thumb {
//     border-radius: 6px;
//     /* make the thumb rounded */
// }



.custom-max-width{
max-width: 90% !important;
}

// Large devices (desktops, 992px and up)
@media (min-width: 600px) {
    .custom-max-width {
        max-width: 100% !important;
    }
}
 


// Import Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/offcanvas";